<div class="mx-4 md:mx-32 pt-8">

    <div class="flex flex-col">

        <h1 class="font-bold mb-8 text-4xl text-color-pc">Hi, {{ firstName }}</h1>
        <div class="flex flex-col md:flex-row gap-4">
            <!-- <div class="basis-1/2">
                <div class="mb-4 bg-white rounded-lg shadow-md">
                    <div class="flex flex-col p-4">
                        <mat-icon aria-hidden="false" aria-label="Menu icon" fontIcon="person_pin"
                            class="mat-icon-md mb-3 mt-4 text-[#217C79]"></mat-icon>
                        <h2 class="text-color-pc text-2xl mb-2 font-black">Company Profile</h2>

                        <h2 class="mb-4 text-base">Fill in your company profile to unlock tailored financial insights
                            and improve your business strategy.</h2>
                        <button class="w-full md:w-48 my-2 justify-self-end !rounded-xl" mat-raised-button
                            color="primary" [routerLink]="['/company']">Complete Profile</button>

                    </div>
                </div>
            </div> -->
            <div class="basis-1/2">
                <div class="mb-4 bg-white rounded-lg shadow-md">
                    <div class="flex flex-col p-4">
                        <mat-icon aria-hidden="false" aria-label="Menu icon" fontIcon="school"
                            class="mat-icon-md mb-3 mt-4 text-[#217C79]"></mat-icon>
                        <h2 class="text-color-pc text-2xl mb-2 font-black">Academy</h2>

                        <h2 class="mb-4 text-base">Enhance your business acumen with our academy, offering valuable
                            resources and expert knowledge.</h2>
                        <button class="w-full md:w-48 my-2 justify-self-end !rounded-xl" mat-raised-button
                            color="primary" [routerLink]="['/academy']">Start Learning</button>

                    </div>
                </div>
            </div>
            <div class="basis-1/2">
                <div class="mb-4 bg-white rounded-lg shadow-md">
                    <div class="flex flex-col p-4">
                        <mat-icon aria-hidden="false" aria-label="Menu icon" fontIcon="business_center"
                            class="mat-icon-md mb-3 mt-4 text-[#217C79]"></mat-icon>
                        <h2 class="text-color-pc text-2xl mb-2 font-black">Exseede ERP for SMEs</h2>

                        <h2 class="mb-4 text-base">Digitalizing your core business processes for enhanced transparency,
                            decision making and performance.</h2>
                        <a class="w-full md:w-48 my-2 justify-self-end !rounded-xl" mat-raised-button color="primary"
                            href="https://calendly.com/carolin-link/advisory-introduction" target="_blank">Learn
                            More</a>

                    </div>
                </div>
            </div>




        </div>
        <div class="flex flex-col md:flex-row gap-4">


            <div class="basis-1/2">
                <div class="mb-4 bg-white rounded-lg shadow-md">
                    <div class="flex flex-col p-4">
                        <mat-icon aria-hidden="false" aria-label="Menu icon" fontIcon="query_stats"
                            class="mat-icon-md mb-3 mt-4 text-[#217C79]"></mat-icon>
                        <h2 class="text-color-pc text-2xl mb-2 font-black">Financial Insights</h2>

                        <h2 class="mb-4 text-base">Get in-depth insights into your company's performance with our
                            comprehensive financial analysis tool.</h2>
                        <button class="w-full md:w-48 my-2 justify-self-end !rounded-xl" mat-raised-button
                            color="primary" [routerLink]="['/financial-wizard']">Get Insights</button>

                    </div>
                </div>
            </div>



            <div class="basis-1/2">
                <div class="mb-4 bg-white rounded-lg shadow-md">
                    <div class="flex flex-col p-4">
                        <mat-icon aria-hidden="false" aria-label="Menu icon" fontIcon="volunteer_activism"
                            class="mat-icon-md mb-3 mt-4 text-[#217C79]"></mat-icon>
                        <h2 class="text-color-pc text-2xl mb-2 font-black">Funding Database</h2>

                        <h2 class="mb-4 text-base">Explore an extensive database of funding opportunities to fuel your
                            business growth and expansion.</h2>
                        <button class="w-full md:w-48 my-2 justify-self-end !rounded-xl" mat-raised-button
                            color="primary" [routerLink]="['/funding']">Find Funds</button>

                    </div>
                </div>
            </div>

        </div>

    </div>


</div>