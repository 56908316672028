import { Component } from '@angular/core';
import { MaterialModule } from '../shared/ui/material/material.module';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UserService } from '../shared/data-access/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  imports: [CommonModule, RouterModule, MaterialModule],
  standalone: true
})
export class DashboardComponent {

  firstName: string | undefined;
  lastName: string | undefined;

  constructor(
    private userService: UserService
  ) {
    this.firstName = this.userService.user().firstName;
    this.lastName = this.userService.user().lastName;
  }
}
