import { Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { acceptedconditionsGuard } from './shared/utils/guards/accepted-conditions.guard';
import { ShellComponent } from './shared/ui/shell/shell.component';
import { DashboardComponent } from './dashboard/dashboard.component';

export const routes: Routes = [
    {
        path: '',
        component: ShellComponent,
        children: [
            { 
                path: '',
                component: DashboardComponent,
                canActivate: [AuthGuard]
            },
            { 
                path: 'welcome',
                component: DashboardComponent
            },
            { 
                path: 'funding',
                loadComponent: () => import('./funding/funding.component')
                    .then(p => p.FundingComponent)
            },
            { 
                path: 'company',
                loadChildren: () => import('./company/company.routes')
                    .then(m => m.routes),
            },
            { 
                path: 'account',
                loadChildren: () => import('./account/account.routes')
                    .then(m => m.routes),
            },
            { 
                path: 'financial-wizard',
                loadComponent: () => import('./financial-wizard/features/financial-wizard/financial-wizard.component')
                    .then(p => p.FinancialWizardComponent)
                
            },
            {
                path: 'erp',
                loadChildren: () => import('./erp-dashboard/erp.routes')
                .then(m => m.routes),
            },
        ]
    },
    
    {
        path: 'terms-conditions',
        loadComponent: () => import('./onboarding/features/terms/terms.component')
            .then(p => p.TermsComponent)
    },
    {
        path: 'academy',
        loadChildren: () => import('./academy/academy.routes')
        .then(m => m.routes),
    },
    { path: '**', redirectTo: 'welcome', pathMatch: 'full' },
];
